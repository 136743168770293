import z from 'zod';

// eslint-disable-next-line no-useless-escape
const phoneRegExp = /^([\(]{1}[0-9]{3}[\)]{1}[ ]{1}[0-9]{3}[\-]{1}[0-9]{4})$/;

export const bookSchema = z.object({
  email: z.string().email(),
  phone: z
    .string()
    .regex(phoneRegExp, {
      message: 'Phone must match the following: (999) 999-9999',
    })
    .min(1)
    .max(15)
    .nullable(),

  full_name: z.string().min(1),
  company_name: z.string().min(1),
  count_employees: z
    .string()
    .transform((value) => parseInt(value))
    .nullable(),
  key_features: z.string().max(5000).nullable(),
  industry: z.string().nullable(),
});

export type User = z.infer<typeof bookSchema>;
