'use client';

import { useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import InputMask from 'react-input-mask';
import { zodResolver } from '@hookform/resolvers/zod';
import { sendGAEvent, sendGTMEvent } from '@next/third-parties/google';
import { useReCaptcha } from 'next-recaptcha-v3';
import styled from 'styled-components';

import { bookSchema } from './schema';

import Button from '@/components/Button/Button';
import Input from '@/components/Input/Input';
import Success from '@/components/Success/Success';
import { useNewsletterModalContext } from '@/context/newsletter-modal.context';
import { devices } from '@/styles/breakpoints';

interface EmailPayload {
  name: string;
  email: string;
  description: string;
}

export default function BookDemoForm({ isModal = false }) {
  const inputRef = useRef(null);
  const { setIsModalOpened } = useNewsletterModalContext();
  const [hasSuccessfullySentMail, setHasSuccessfullySentMail] = useState(false);
  const [hasErrored, setHasErrored] = useState(false);
  const [inputLength, setInputLength] = useState(0);
  const { executeRecaptcha } = useReCaptcha();
  const {
    handleSubmit,
    register,
    setValue,
    getValues,
    control,
    clearErrors,
    formState: { errors, isSubmitting, isSubmitSuccessful },
  } = useForm<FormData>({
    resolver: zodResolver(bookSchema),
  });

  const handleInputChange = (e) => {
    const inputValue = e.target.value;
    setInputLength(inputValue.length);
    setValue('key_features', inputValue);
  };

  async function onSubmit(payload: EmailPayload) {
    try {
      const token = await executeRecaptcha('form_submit');

      await fetch('/api/form/send', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          referer: 'https://orcatec.com',
          // 'x-api-key': 'd6SlHdfwFoGjAJ5r8GptcUYr6zMX3Nfj',
        },
        body: JSON.stringify({
          subject: 'Email from contact form',
          data: payload,
          token,
        }),
      });

      sendGTMEvent({
        event: 'form_sent',
        value: process.env.NEXT_PUBLIC_GOOGLE_TAG,
      });
      sendGAEvent([{ event: 'form_sent' }]);

      setTimeout(() => setIsModalOpened(false), 1000);
    } catch {
      setHasErrored(true);
      return;
    }

    setHasSuccessfullySentMail(true);
  }

  const isSent = isSubmitSuccessful;
  const isDisabled = isSubmitting || isSent;
  const isSubmitDisabled = Object.keys(errors).length > 0 || isDisabled;

  return (
    <Wrapper className="book-demo-modal">
      {hasSuccessfullySentMail ? (
        <Success />
      ) : (
        <Form
          action=""
          method="POST"
          onSubmit={(e) => {
            e.stopPropagation();
            e.preventDefault();
            handleSubmit(onSubmit)(e);
            return false;
          }}
        >
          <FormWrapper $ismodal={isModal}>
            {' '}
            {hasErrored && (
              <ErrorMessage>
                Couldn&apos;t send email. Please try again.
              </ErrorMessage>
            )}
            <InputStack>
              <LabelMessage $error={!!errors?.full_name}>
                <label htmlFor="full_name">
                  Full Name<span className="required">*</span>
                </label>
              </LabelMessage>
              <Input
                id="full_name"
                disabled={isDisabled}
                {...register('full_name', { required: true })}
                $error={!!errors?.full_name}
              />
              {errors?.full_name && (
                <ErrorMessage>Name is required</ErrorMessage>
              )}
            </InputStack>
            <InputGroup>
              <InputStack>
                <LabelMessage $error={!!errors?.phone}>
                  <label htmlFor="phone">
                    Phone<span className="required">*</span>
                  </label>
                </LabelMessage>
                <Controller
                  name="phone"
                  control={control}
                  defaultValue={''}
                  render={({ field: { value, onChange, onBlur } }) => (
                    <InputMask
                      mask="(999) 999-9999"
                      value={value}
                      onChange={onChange}
                      onBlur={onBlur}
                      ref={inputRef}
                    >
                      {(props) => (
                        <Input id="phone" $error={!!errors?.phone} {...props} />
                      )}
                    </InputMask>
                  )}
                />
                {errors?.phone && (
                  <ErrorMessage>Phone is required</ErrorMessage>
                )}
              </InputStack>
              <InputStack>
                <LabelMessage $error={!!errors?.email}>
                  <label htmlFor="email">
                    Email<span className="required">*</span>
                  </label>
                </LabelMessage>
                <Input
                  id="email"
                  disabled={isDisabled}
                  {...register('email', { required: true })}
                  $error={!!errors?.email}
                />

                {errors?.email && (
                  <ErrorMessage>
                    {getValues('email')
                      ? 'Email is invalid'
                      : 'Email is required'}{' '}
                  </ErrorMessage>
                )}
              </InputStack>
            </InputGroup>
            <InputStack>
              <LabelMessage $error={!!errors?.company_name}>
                <label htmlFor="company_name">
                  Company Name<span className="required">*</span>
                </label>
              </LabelMessage>
              <Input
                id="company_name"
                disabled={isDisabled}
                {...register('company_name', { required: true })}
                $error={!!errors?.company_name}
              />
              {!!errors?.company_name && (
                <ErrorMessage>Company name is required</ErrorMessage>
              )}
            </InputStack>
            <InputGroup>
              <InputStack>
                <LabelMessage>
                  <label htmlFor="industry">Your industry</label>
                </LabelMessage>
                <Input
                  id="industry"
                  disabled={isDisabled}
                  {...register('industry')}
                />
                {errors?.industry && (
                  <ErrorMessage>Industry is required</ErrorMessage>
                )}
              </InputStack>
              <InputStack>
                <LabelMessage>
                  <label htmlFor="count_employees">Number of employees</label>
                </LabelMessage>
                <Input
                  id="count_employees"
                  type="number"
                  disabled={isDisabled}
                  {...register('count_employees', { required: true })}
                />
              </InputStack>
            </InputGroup>
            <InputStack>
              <LabelMessage $error={!!errors?.source}>
                <label htmlFor="source">Source</label>
              </LabelMessage>
              <Input
                id="source"
                disabled={isDisabled}
                {...register('source', { required: true })}
                $error={!!errors?.source}
              />
              {!!errors?.source && (
                <ErrorMessage>Company name is required</ErrorMessage>
              )}
            </InputStack>
            <InputStack>
              <LabelMessage>
                <label htmlFor="key_features">Key features needed</label>
                <span>{`${inputLength || 0}/5000`}</span>
              </LabelMessage>
              <Textarea
                as="textarea"
                id="key_features"
                disabled={isDisabled}
                {...register('key_features')}
                onChange={handleInputChange}
              />
              <CapchaBlock>
                This site is protected by reCAPTCHA and the Google{' '}
                <a target="_blank" href="https://policies.google.com/privacy">
                  Privacy Policy
                </a>{' '}
                and{' '}
                <a target="_blank" href="https://policies.google.com/terms">
                  Terms of Service
                </a>{' '}
                apply.
              </CapchaBlock>
            </InputStack>
          </FormWrapper>
          <ButtonWrapper className="controls">
            <Button
              className="control-cancel"
              variant="outlined"
              type="submit"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                clearErrors();
                setIsModalOpened(false);
              }}
            >
              Cancel
            </Button>
            <Button
              className="control-subbmit"
              variant="secondary"
              type="submit"
              disabled={isSubmitDisabled}
            >
              Get a Demo
            </Button>
          </ButtonWrapper>
        </Form>
      )}
    </Wrapper>
  );
}

const CapchaBlock = styled.small`
  padding-top: 4px;
  text-align: left;
  display: block;
  color: #4d4d4d;
  font-size: 12px;

  & a {
    color: #206fed;
    font-size: 12px;
    text-decoration: none;
  }
`;

const Wrapper = styled.div`
  margin: 0 auto;
  background-color: var(--whiteColor);
  width: 100%;
  max-width: 552px;
  border-radius: 10px;
  padding: 16px;
  min-height: 400px;

  @media only screen and ${devices.desktop} {
    padding: 40px;
    max-height: 100%;
  }
`;

const Form = styled.form`
  & textarea {
    resize: none;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center !important;
  gap: 20px;
  padding-top: 16px;
  @media only screen and ${devices.desktop} {
    & button {
      width: 114px;
      padding: 10px 20px;
    }
  }
`;

const FormWrapper = styled.div<{ $ismodal: boolean }>`
  max-height: ${(props) => (props.$ismodal ? '500px' : '100%')};
  overflow-x: ${(props) => (props.$ismodal ? 'auto' : 'unset')};
  scrollbar-width: 2px;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    width: 4px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: rgba(0, 0, 0, 0.2);
  }

  @media only screen and ${devices.desktop} {
    max-height: 100%;
    overflow: unset;
  }
`;

const InputGroup = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;

  & input {
    width: 100%;
  }

  @media only screen and ${devices.desktop} {
    display: flex;

    flex-direction: row;
    gap: 20px;
  }
`;

const InputStack = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 16px;
  & .required {
    color: var(--errorColor);
    margin-left: 4px;
  }
`;

const ErrorMessage = styled.p`
  color: var(--errorColor);
  font-family: var(--font-roboto);
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  padding-top: 4px;
`;

const LabelMessage = styled.p<{ $error: boolean }>`
  color: ${(props) => (props.$error ? 'red' : 'var(--greyColor)')};
  font-family: var(--font-roboto);
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 2px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Textarea = styled(Input)`
  width: 100%;
  min-height: 80px;
`;
