import styled from 'styled-components';

import { SuccessIcon } from '@/public/icons';

export default function Success() {
  return (
    <Wrapper>
      <SuccessIcon />
      <p>Your form has been sent successfully </p>

      <noscript>
        <iframe
          src="https://www.googletagmanager.com/ns.html?id=GTM-KKBM77Q"
          style={{ visibility: 'hidden', width: '0px', height: '0px' }}
        ></iframe>
      </noscript>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  & > *:not(:first-child) {
    margin-top: 5rem;
  }

  p {
    color: #262626;
    text-align: center;
    font-family: var(--font-roboto);
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
    margin-bottom: 10px;
  }
`;
