'use client';

import React, {
  createContext,
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useContext,
  useState,
} from 'react';

interface NewsletterModalContextProps {
  isModalOpened: boolean;
  setIsModalOpened: Dispatch<SetStateAction<boolean>>;
}

export const NewsletterModalContext =
  createContext<NewsletterModalContextProps | null>(null);

function NewsletterModalContextProvider<T>({ children }: PropsWithChildren<T>) {
  const [isModalOpened, setIsModalOpened] = useState(false);

  return (
    <NewsletterModalContext.Provider
      value={{
        isModalOpened,
        setIsModalOpened,
      }}
    >
      {children}
    </NewsletterModalContext.Provider>
  );
}

export default NewsletterModalContextProvider;

export function useNewsletterModalContext() {
  const context = useContext(NewsletterModalContext);
  if (!context) {
    throw new Error(
      'useNewsletterModalContext can only be used inside NewsletterModalContextProvider'
    );
  }
  return context;
}
