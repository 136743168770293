import * as React from 'react';
import { clsx } from 'clsx';
import styled from 'styled-components';

import { devices } from '@/styles/breakpoints';

const TextButtonVariant = [
  'primary',
  'basic',
  'outlined',
  'secondary',
  'link',
] as const;

type TextButtonProps = {
  variant?: (typeof TextButtonVariant)[number];
  color: string;
  fullWidth: boolean;
} & React.ComponentPropsWithRef<'button'>;

const Button = React.forwardRef<HTMLButtonElement, TextButtonProps>(
  (
    {
      children,
      className,
      variant = 'primary',
      color = '',
      disabled: buttonDisabled,
      fullWidth = false,
      ...rest
    },
    ref
  ) => {
    return (
      <CustomButton
        ref={ref}
        type="button"
        className={clsx(
          className,
          variant,
          color,
          fullWidth ? 'fullWidth' : ''
        )}
        disabled={buttonDisabled}
        {...rest}
      >
        {children}
      </CustomButton>
    );
  }
);

Button.displayName = 'Button';

const CustomButton = styled.button`
  padding: 14px 24px;
  font-family: var(--font-roboto);
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;
  outline: none;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  background-color: #fff;
  @media only screen and ${devices.desktop} {
    padding: 10px 24px;
  }

  &.outlined {
    background-color: transparent;
    border: 1px solid rgba(214, 219, 237, 0.5);
    color: #4d4d4d;
    background-color: white;
  }
  &.secondary {
    background-color: var(--mainBlue);
    color: white;
    border: none;
  }
  &.link {
    padding: 0;
    background-color: transparent;
  }

  &.blue {
    color: var(--mainBlue);
  }
  &.fullWidth {
    width: 100%;
  }
  &.bluer {
    background-color: #f1f2fd;
    color: #206fed;
  }
`;

export default Button;
