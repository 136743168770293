import styled from 'styled-components';

const Input = styled.input<{ $error: boolean }>`
  width: 100%;
  border: ${(props) =>
    props.$error ? '1px solid red' : '1px solid var(--inputBorder)'};
  color: var(--greyColor);
  border-radius: 6px;
  font-size: 12px;
  height: 36px;
  padding: 2px 10px;
  outline-color: var(--inputBorder);

  &:focus {
    outline: none;
    box-shadow: var(--shadow-lg);
  }
  &::placeholder {
    color: #999;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }
`;

export default Input;
